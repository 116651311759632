import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const TextOnImageBlock = ({ block }) => {
  return (
    <>
      {block.image?.filename?.length > 0 && (
        <BlockWrapper block={block} blockWidth="screen">
          <div className="relative">
            <Image
              className="w-full h-[100vh] object-cover"
              image={block.image}
            />
            <div className="absolute inset-0 z-10 bg-black bg-opacity-60"></div>
            <div className="absolute inset-0 z-20">
              <div className="flex flex-col justify-center h-full max-w-screen-md mx-auto px-grid">
                <span className="text-xl leading-relaxed tracking-widest text-center text-white uppercase md:text-2xl">
                  {block.text}
                </span>
              </div>
            </div>
          </div>
        </BlockWrapper>
      )}
    </>
  )
}

export default TextOnImageBlock
